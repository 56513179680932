import React, {useEffect, useState} from 'react';

const PasswordCheck = ({enteredNewPassword, enteredNewPasswordIsTouched}) => {
    const [superPoorPassword, setSuperPoorPassword] = useState(false);
    const [poorPassword, setPoorPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [passwordError, setPasswordErr] = useState("");


    const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9])/;
    ;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const whitespaceRegExp = /^$|\s+/;

    const poorPasswordTest = poorRegExp.test(enteredNewPassword);
    const weakPasswordTest = weakRegExp.test(enteredNewPassword);
    const strongPasswordTest = strongRegExp.test(enteredNewPassword);
    const whiteSpaceTest = whitespaceRegExp.test(enteredNewPassword)

    useEffect(() => {
        if (whiteSpaceTest === '') {
            setPasswordErr("Password is Empty");
        } else {
            if (whiteSpaceTest) {
                setPasswordErr("Whitespaces are not allowed");
            }
            if (enteredNewPassword.length === 0) {
                setPoorPassword(false);
            }
            if (( enteredNewPassword.length >= 1) && enteredNewPasswordIsTouched) {
                setSuperPoorPassword(true);
                setPasswordErr("Password is Poor");
            }else {
                setSuperPoorPassword(false)
            }
            if (( enteredNewPassword.length > 2) && enteredNewPasswordIsTouched) {
                setPoorPassword(true);
                setPasswordErr("Password is Poor");
            } else {
                setPoorPassword(false)
            }
            if (enteredNewPassword.length >= 4 && poorPasswordTest && (weakPasswordTest || strongPasswordTest)) {
                setWeakPassword(true);
                setPasswordErr("Password is Weak");
            } else {
                setWeakPassword(false);
            }
            if (enteredNewPassword.length >= 8 && (poorPasswordTest && weakPasswordTest) && strongPasswordTest) {
                setStrongPassword(true);
                setPasswordErr("Password is Strong");
            } else {
                setStrongPassword(false);
            }
        }
    }, [enteredNewPassword])

    return (
        <div className="form__group">
            <div className="form__field">
                <div id="typepass">
                    <div id="strengthResult">
                        <div className="progress" style={{height: "10px"}}>
                            <div className="progress-wrapper"><span
                                className="progress-title">Moc hasła</span>
                                <span className="dot"
                                      style={{backgroundColor: `${superPoorPassword ? "green" : "#D8E8F5"}`}}></span>
                                <span className="dot"
                                      style={{backgroundColor: `${poorPassword ? "green" : "#D8E8F5"}`}}></span>
                                <span className="dot"
                                      style={{backgroundColor: `${weakPassword ? "green" : "#D8E8F5"}`}}></span>
                                <span className="dot"
                                      style={{backgroundColor: `${weakPassword ? "green" : "#D8E8F5"}`}}></span>
                                <span className="dot"
                                      style={{backgroundColor: `${strongPassword ? "green" : "#D8E8F5"}`}}></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PasswordCheck;