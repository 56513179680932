import React, { useMemo, useState } from 'react';
import { Modal } from '@mantine/core';
import { motion } from 'framer-motion';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	authorizedDeleteWithId,
	authorizedGet,
	authorizedPatchWithId,
} from '../../fetchFunctions';
import LibraryItem from './LibraryItem';
import axios from 'axios';

const Libary = ({ chosenCat, setChosenCat, unathLib }) => {
	const queryClient = useQueryClient();

	const authLibQuery = useQuery(
		'clientLibrary',
		() => authorizedGet('/client_libraries'),
		{
			placeholderData: [],
			enabled: !unathLib,
		}
	);

	const unathLibQuery = useQuery(
		['questLibrary'],
		async () => {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/client_libraries`,
				{
					params: unathLib,
				}
			);
			return response.data;
		},
		{
			placeholderData: [],
			enabled: !!unathLib,
		}
	);

	const libData = !!unathLib ? unathLibQuery.data : authLibQuery.data;

	const [opened, setOpened] = useState(false);
	const [chosenImg, setChosenImg] = useState(undefined);
	const [chosenLibrary, setChosenLibrary] = useState(undefined);
	const [chosenCatToChange, setChosenCatToChange] = useState(undefined);
	const [newCatTitle, setNewCatTitle] = useState(undefined);
	const [openEditTitle, setOpenEditTitle] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const filteredItems = useMemo(
		() =>
			libData?.reduce((acc, item) => {
				if (!chosenCat || chosenCat.id === item.id) return [...acc, item];

				return acc;
			}, []),
		[chosenCat, libData]
	);

	const changeTitleAuthMutation = useMutation(authorizedPatchWithId, {
		onSuccess: (d) => {
			setChosenCat(d);
			queryClient.invalidateQueries('clientLibrary');
			queryClient.invalidateQueries('questLibrary');
			setOpenEditTitle(false);
		},
	});

	const changeImageCategoryMutation = useMutation(authorizedPatchWithId, {
		onSuccess: () => {
			queryClient.invalidateQueries('clientLibrary');
			queryClient.invalidateQueries('questLibrary');

			setOpened(false);
		},
	});

	const deleteCategoryMutation = useMutation(authorizedDeleteWithId, {
		onSuccess: () => {
			queryClient.invalidateQueries('clientLibrary');
			queryClient.invalidateQueries('questLibrary');
			setTimeout(() => setOpenDelete(false), 1000);
			setChosenCat(undefined);
		},
	});

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="white-box__wrapper" style={{ alignItems: 'center' }}>
					{!openEditTitle && (
						<h2 className="main-title">
							{chosenCat ? chosenCat.title : 'Wszystkie'}
						</h2>
					)}
					{!!chosenCat && !openEditTitle && (
						<a
							onClick={(e) => {
								e.preventDefault();
								setOpenEditTitle(true);
							}}
							href="/"
							className="function-button"
						>
							<i className="ico ico-pen"></i> Zmień nazwę
						</a>
					)}
					{openEditTitle && (
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<input
								type="text"
								onChange={(e) => setNewCatTitle(e.target.value)}
							/>
							<a
								onClick={(e) => {
									e.preventDefault();

									changeTitleAuthMutation.mutate({
										url: `/client_libraries/${chosenCat.id}/change_title`,
										data: {
											title: newCatTitle,
										},
									});
								}}
								href="/"
								className="function-button"
							>
								Zapisz
							</a>
						</div>
					)}
					{!!chosenCat && !chosenCat.isDefault && (
						<a
							style={{
								marginLeft: 'auto',
								color: 'red',
								display: 'flex',
								alignItems: 'start',
							}}
							onClick={(e) => {
								e.preventDefault();
								setOpenDelete(true);
							}}
							href="/"
							className="function-button"
						>
							<i className="ico ico-trash"></i> Usuń kategorię
						</a>
					)}
				</div>
				{filteredItems?.map((libary) => {
					return libary?.images?.map((image) => {
						return (
							<LibraryItem
								key={image.id}
								libary={libary}
								image={image}
								setOpened={setOpened}
								setChosenImg={setChosenImg}
								setChosenLibrary={setChosenLibrary}
							/>
						);
					});
				})}
			</div>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={() => setOpened(false)}
				size="lg"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						className="modal__close js-modal-close"
						href="/"
						onClick={(e) => {
							e.preventDefault();
						}}
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">Przenieś do innej kategorii</h3>
						<form action="test" method="post" className="form js-validate">
							<select
								onChange={(e) => {
									setChosenCatToChange(e.target.value);
								}}
								placeholder="Wpisz nazwę kategorii"
								className="form__input required"
							>
								<option value="" hidden>
									Wybierz kategorię...
								</option>
								{libData
									?.filter((c) => c.title !== chosenLibrary?.title)
									.map((cat) => {
										return (
											<option key={cat.id} value={cat.id}>
												{cat.title}
											</option>
										);
									})}
							</select>
						</form>
						<a
							onClick={(e) => {
								e.preventDefault();
								changeImageCategoryMutation.mutate({
									url: `/client_libraries/${chosenLibrary?.id}/change_library`,
									data: {
										image: chosenImg?.id,
										clientLibraryToChange: chosenCatToChange,
									},
								});
							}}
							className={`sidenav__item sidenav__item--add-category btn btn--primary ${
								!chosenCatToChange && 'a-disabled'
							}`}
							href="/"
						>
							+ Przenieś
						</a>
					</div>
				</div>
			</Modal>
			<Modal
				opened={openDelete}
				withCloseButton={false}
				centered
				onClose={() => setOpenDelete(false)}
				size="lg"
			>
				<div
					className="modal fancybox-content"
					id="category-modal"
					style={{ display: 'inline-block' }}
				>
					<a
						className="modal__close js-modal-close"
						href="/"
						onClick={(e) => {
							e.preventDefault();
							setOpenDelete(false);
						}}
					>
						<span>Zamknij</span>
						<i className="ico ico-cancel"></i>
					</a>
					<div className="modal__inner">
						<h3 className="modal__title">
							Czy na pewno chcesz usunąć kategorię?
						</h3>
						<p>Wszystkie zdjęcia z danej kategorii zostną usunięte!</p>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								gap: 20,
							}}
						>
							<a
								onClick={(e) => {
									e.preventDefault();
									deleteCategoryMutation.mutate({
										url: `/client_libraries`,
										id: chosenCat.id,
									});
								}}
								className="sidenav__item sidenav__item--add-category btn btn--danger "
								href="/"
							>
								Usuń
							</a>
							<a
								onClick={(e) => {
									e.preventDefault();
									setOpenDelete(false);
								}}
								className="sidenav__item sidenav__item--add-category btn btn--primary"
								href="/"
							>
								Anuluj
							</a>
						</div>
					</div>
				</div>
			</Modal>
		</motion.div>
	);
};

export default Libary;
