import moment from 'moment';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { authorizedPatchWithId } from '../../fetchFunctions';
import { useSelector } from 'react-redux';

const LibraryItem = ({
	image,
	setChosenImg,
	setOpened,
	libary,
	setChosenLibrary,
}) => {
	const user = useSelector((state) => state.auth.user);

	const queryClient = useQueryClient();
	const deleteImageFromLibraryMutation = useMutation(authorizedPatchWithId, {
		onSuccess: () => {
			queryClient.invalidateQueries(['clientLibrary']);
			queryClient.invalidateQueries('questLibrary');
		},
	});

	return (
		<div className="materials materials--library">
			<div className="materials__wrapper">
				<div className="materials__photo">
					<img
						style={{ maxWidth: 256, height: 195, objectFit: 'cover' }}
						src={image?.watermarkImage?.contentUrl}
						alt="box_photo"
					/>
				</div>
				<div className="materials__info">
					<div className="materials__info-item">
						<strong>Data wykonania:</strong>{' '}
						{moment(image?.imageGroup?.publisherImageGroupCreatedAt).format(
							'DD.MM.YYYY'
						)}
					</div>
					<div className="materials__info-item">
						<strong>Dostawca:</strong> {image?.publisher?.insideId}
					</div>
					<div className="materials__info-item">
						<strong>Autor: </strong> {image?.imageGroup?.finalAuthor}
					</div>
					<div className="materials__info-item">
						<strong>Opis:</strong> {image?.imageGroup?.finalDescription}
					</div>
				</div>

				<div className="materials__download">
					<a
						onClick={(e) => {
							e.preventDefault();
							setChosenLibrary(libary);
							setChosenImg(image);
							setOpened(true);
						}}
						href="/"
						className="materials__download-button"
					>
						<i className="ico ico-change-icon"></i> Przenieś do innej kategorii
					</a>
					{user ? (
						<a
							href={`${process.env.REACT_APP_SITE_URL}photos/${image.imageGroup.id}/${image.id}?openConditions=true`}
							className="materials__download-button"
							target="_blank"
							rel="noreferrer"
						>
							<i className="ico ico-basket-icon"></i> Dodaj do koszyka
						</a>
					) : (
						<a
							href={`${process.env.REACT_APP_SITE_URL}photos/${image.imageGroup.id}/${image.id}?download=true`}
							className="materials__download-button"
							target="_blank"
							rel="noreferrer"
						>
							<i className="ico ico-download2-icon"></i> Pobierz obraz
						</a>
					)}
					<a
						rel="noreferrer"
						target="_blank"
						href={`${process.env.REACT_APP_SITE_URL}photos/${image.imageGroup.id}/${image.id}`}
						className="materials__download-button"
					>
						{' '}
						<i className="ico ico-image-icon"></i> Przejdź do podstrony z
						obrazem
					</a>
					<a
						onClick={(e) => {
							e.preventDefault();
							deleteImageFromLibraryMutation.mutate({
								url: `/client_libraries/${libary.id}/remove_image_from_library`,
								data: {
									image: image.id,
								},
							});
						}}
						href="/"
						className="materials__download-button"
					>
						<i className="ico ico-trash-icon"></i> Usuń z biblioteki
					</a>
				</div>
			</div>
		</div>
	);
};

export default LibraryItem;
