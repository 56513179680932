import axios from 'axios';
import { authorizedFetch } from './authorizedFetch';

const PER_PAGE = 10;

export const getPurchaseItems = async ({ queryKey }) => {
	const response = await authorizedFetch(
		`${queryKey[0]}?page=${queryKey[1]}&itemsPerPage=${PER_PAGE}&client=${
			queryKey[2]
		}&simplesearch=${queryKey[3]}&order[createdAt]=${queryKey[4]}${
			queryKey[5] ? `&image.imageGroup.categories.id=${queryKey[5]}` : ''
		}`,
		{
			headers: {
				Accept: 'application/ld+json',
			},
		}
	);

	return {
		items: response['hydra:member'],
		total: response['hydra:totalItems'],
	};
};

export const getClient = async ({ queryKey }) => {
	const response = await authorizedFetch(`${queryKey[0]}/${queryKey[1]}`);
	return response;
};

export const getImageCategories = async ({ queryKey }) => {
	return await authorizedFetch(
		`${queryKey[0]}?pagination=${false}&exists[parentCategory]=false`
	);
};

export const getMailQueues = async ({ queryKey }) => {
	return await authorizedFetch(`${queryKey[0]}?pagination=${false}`);
};

export const getContents = async ({ queryKey }) => {
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/${queryKey[0]}?pagination=${false}`
	);
	return response.data;
};

export const getConversations = async ({ queryKey }) => {
	return await authorizedFetch(
		`${queryKey[0]}?clientReceiver=${queryKey[1]}&order[createdAt]=desc`
	);
};

export const getConversation = async ({ queryKey }) => {
	return await authorizedFetch(`${queryKey[0]}/${queryKey[1]}`);
};

export const postConversation = async (payload) => {
	const response = await authorizedFetch('conversations', {
		method: 'POST',
		body: JSON.stringify({
			title: payload.title,
			clientReceiver: payload.clientReceiver,
		}),
	});

	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: response.id,
			clientCreator: payload.clientReceiver,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};

export const sendMessage = async (payload) => {
	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	const response = await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: payload.conversationId,
			clientCreator: payload.clientCreator,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};
