import React, { useState } from 'react';
import Login from '../Login/Login';
import Register from '../Register/Register';
import PasswordReminder from '../PasswordReminder/PasswordReminder';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const LoginBox = ({
	title,
	description,
	button1,
	button2,
	button3,
	endpoint,
}) => {
	const user = useSelector((state) => state.auth.user);
	const token = Cookies.get('token');
	const [isOpenLoginBox, setIsOpenLoginBox] = useState(false);
	const [isRegister, setIsRegister] = useState(false);
	const [isPasswordReminder, setIsPasswordReminder] = useState(false);
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
			<h2 className="mp-flex-white__title">{title}</h2>
			<div
				className="mp-flex-white__text"
				style={{ justifyContent: 'space-between' }}
			>
				{description}
			</div>
			<div className="mp-flex-white__wrapper">
				{button1 && user && (
					<a
						href="#"
						className="mp-flex-white__link"
						style={{ minHeight: 55, display: 'flex', alignItems: 'center' }}
					>
						{button1}
					</a>
				)}
				{button2 && user && (
					<a
						href="#"
						className="mp-flex-white__link"
						style={{ minHeight: 55, display: 'flex', alignItems: 'center' }}
					>
						{button2}
					</a>
				)}
				{!isOpenLoginBox && !token && (
					<a
						onClick={() => setIsOpenLoginBox(true)}
						href="#"
						className="btn btn--short js-login-button"
						style={{ margin: '0px 0px 0px auto' }}
					>
						<i
							className="ico ico-user-icon-thick"
							style={{ marginRight: 12 }}
						></i>{' '}
						{button3}
					</a>
				)}
				{isOpenLoginBox && !token && (
					<a
						onClick={() => setIsOpenLoginBox(false)}
						href="#"
						className="btn btn--close js-close-button"
						style={{ margin: '0px 0px 0px auto' }}
					>
						<i className="ico ico-close-icon"></i>
					</a>
				)}
			</div>

			{!isRegister && !isPasswordReminder && isOpenLoginBox && (
				<Login
					setIsRegister={setIsRegister}
					setIsPasswordReminder={setIsPasswordReminder}
				/>
			)}
			{isRegister && !isPasswordReminder && isOpenLoginBox && (
				<Register endpoint={endpoint} setIsRegister={setIsRegister} />
			)}
			{isPasswordReminder && isOpenLoginBox && (
				<PasswordReminder setIsPasswordReminder={setIsPasswordReminder} />
			)}
		</div>
	);
};

export default LoginBox;
