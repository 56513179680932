import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Help = () => {
	const [messageTopic, setMessageTopic] = useState('');
	const [message, setMessage] = useState('');

	const sendMessageHandler = (e) => {
		e.preventDefault();
	};

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--larger"
		>
			<div className="white-box__inner">
				<h2 className="main-title main-title--25 main-title--mb-45">
					Potrzebujesz pomocy?
				</h2>

				<div className="row no-gutters">
					<div className="col-md-6 col-lg-6 col-xlg-5">
						<div className="contact-help-wrapper">
							<h3 className="main-title-heading">ZADZWOŃ LUB NAPISZ</h3>

							<div className="contact-item">
								<span className="icon">
									<i className="ico ico-phone-icon"></i>
								</span>
								<a href="tel:+48123456789" className="link">
									+48 123 456 789
								</a>
							</div>

							<div className="contact-item">
								<span className="icon">
									<i className="ico ico-mail-icon"></i>
								</span>
								<a href="mailto:kontakt@gettimages.pl" className="link">
									kontakt@gettimages.pl
								</a>
							</div>
						</div>
					</div>

					<div className="col-md-6 col-lg-6 col-xlg-5 col-xlg-offset-1">
						<h3 className="main-title-heading">WYPEŁNIJ FORMULARZ</h3>

						<form onSubmit={sendMessageHandler}>
							<div className="form__group">
								<div className="form__field">
									<input
										onChange={(e) => setMessageTopic(e.target.value)}
										type="text"
										name="f-default-2"
										placeholder="Temat wiadomości"
										className="form__input required"
										id="f-default-2"
									/>
								</div>
							</div>
							<div className="form__group">
								<div className="form__field">
									<textarea
										onChange={(e) => setMessage(e.target.value)}
										name="f-horizontal-15"
										rows="5"
										className="form__input"
										id="f-horizontal-15"
										placeholder="Treść wiadomości"
									></textarea>
								</div>
							</div>
							<div className="form__actions mt-10">
								<button className="btn btn--block">Wyślij wiadomość</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default Help;
