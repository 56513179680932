import { authorizedFetch } from './authorizedFetch';

export const postConversation = async (payload) => {
	const response = await authorizedFetch('conversations', {
		method: 'POST',
		body: JSON.stringify({
			title: payload.title,
			clientReceiver: payload.clientReceiver,
		}),
	});

	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: response.id,
			clientCreator: payload.clientReceiver,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};

export const sendMessage = async (payload) => {
	const attachments = await Promise.all(
		payload.files.map((file) => {
			const attachmentBody = new FormData();
			attachmentBody.append('file', file);

			return authorizedFetch(
				'attachments',
				{
					method: 'POST',
					body: attachmentBody,
					'Content-Type': undefined,
				},
				true
			);
		})
	);

	const response = await authorizedFetch('messages', {
		method: 'POST',
		body: JSON.stringify({
			content: payload.content,
			conversation: payload.conversationId,
			clientCreator: payload.clientCreator,
			attachments: attachments.map((attachment) => attachment.id),
		}),
	});

	return await response;
};
