import React, { useEffect, useState } from 'react';
import { LoadingOverlay, TextInput } from '@mantine/core';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { validatePassword } from '../../utils';
import axios from 'axios';

async function submitChange({ password, token }) {
	await axios.post(`${process.env.REACT_APP_API_URL}/reset_password/process`, {
		password,
		token,
	});

	return 1;
}

const initialValues = {
	password: '',
	repeatPassword: '',
};

const SetPassword = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const { token } = useParams();

	useEffect(() => {
		if (!token) navigate('/');
		//eslint-disable-next-line
	}, []);

	const form = useForm({
		initialValues,
		validate: {
			password: (value, values) => {
				if (value !== values.repeatPassword)
					return 'Oba hasła nie są identyczne!';
				if (!validatePassword(value))
					return 'Hasło musi zawierać co najmniej 8 znaków, 1 duża literę, 1 małą literę, cyfrę oraz znak specjalny';
				return null;
			},
			repeatPassword: (value, values) => {
				if (value !== values.password) return 'Oba hasła nie są identyczne!';
				if (!validatePassword(value))
					return 'Hasło musi zawierać co najmniej 8 znaków, 1 duża literę, 1 małą literę, cyfrę oraz znak specjalny';
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setLoading(true);
		submitChange({ password: form.values.password, token })
			.then((res) => {
				setLoading(false);
				setSuccess(true);
				setError(false);
				form.reset();
			})
			.catch((e) => {
				setError(true);
				setLoading(false);
			});
	};

	return (
		<div
			className="mp-flex-wrapper__left mp-flex-white js-mp-flex-white tab active login mp-flex-wrapper__small"
			id="tab1"
		>
			<h2 className="mp-flex-white__title">Ustaw nowe hasło</h2>
			<div className="mp-flex-white__subtitle">
				Wpisz dwukrotnie nowe hasło, następnie naciśnij "Zmień hasło"
			</div>

			<form style={{ width: '100%' }} onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={loading} />
				{!success && (
					<>
						<div className="form__group">
							<div className="form__field mb-10">
								<TextInput
									type="password"
									placeholder="Nowe hasło"
									{...form.getInputProps('password')}
								/>
							</div>
							<div className="form__field">
								<TextInput
									type="password"
									placeholder="Powtórz hasło"
									{...form.getInputProps('repeatPassword')}
								/>
							</div>
						</div>
						<div className="form__actions mb-20">
							<button type={'submit'} className="btn btn--block">
								Resetuj hasło
							</button>
						</div>
					</>
				)}
				{error && <p className="error text-center">Coś poszło nie tak!</p>}
				{success && (
					<div className="form__group">
						<div className="account-info-check">
							<Link className="btn btn--block" to="/">
								Wróć do logowania
							</Link>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};

export default SetPassword;
