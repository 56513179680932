import React from 'react';
import LoginBox from './LoginBox';

const Authorization = () => {
	return (
		<>
			<div
				className="mp-flex-wrapper__left mp-flex-white js-mp-flex-white tab active login mp-flex-wrapper__small"
				id="tab1"
			>
				<LoginBox
					title={'Pobieraj, wykorzystuj materiały z naszego serwisu'}
					description={
						'i zyskaj więcej czasu na projektowanie. Zapoznawaj się na bieżąco z Warunkami, przyzwoleniami i ograniczeniami dla pobrań.'
					}
					button1={'Twoja biblioteka'}
					button2={'Pomoc'}
					button3={'Zaloguj się'}
					endpoint={'clients'}
				/>
			</div>
		</>
	);
};

export default Authorization;
