import React, { useState } from 'react';
import { Modal } from '@mantine/core';
import logo from '../../assets/img/Pobierający/logo.svg';
import fundationLogo from '../../assets/img/Footer/logo-fundacja.png';
import { useQuery } from 'react-query';
import { getContents } from '../../api/get';

const Footer = () => {
	const [opened, setOpened] = useState(false);
	const [modalSlug, setModalSlug] = useState('');

	const contentsQuery = useQuery(['setting_contents'], getContents, {
		initialData: [],
		select: (data) =>
			data.reduce((acc, curr) => {
				acc[curr.insideKey] = curr.content;
				return acc;
			}, {}),
	});

	return (
		<footer className="footer">
			<div className="container">
				<div className="upper-wrapper">
					<div className="logo-wrapper">
						<a href="#" className="footer__logo">
							<img src={logo} alt="gettimages logo" />
						</a>
						<a href="#" className="footer__central-logo" target="_blank">
							<img src={fundationLogo} alt="GettImages Logo" />
						</a>
					</div>
					<div className="contact-wrapper">
						<span
							onClick={(e) => {
								setModalSlug('client.aboutUsModal');
								setOpened(true);
							}}
							className="footer__contact-link"
						>
							{contentsQuery.data['client.aboutUsModal.nav']}
						</span>
						<span
							onClick={(e) => {
								setModalSlug('client.contactModal');
								setOpened(true);
							}}
							className="footer__contact-link"
						>
							{contentsQuery.data['client.contactModal.nav']}
						</span>
						<a href="#" className="footer__social-link">
							<i className="ico ico-instagram-icon"></i>
						</a>
						<a href="#" className="footer__social-link">
							<i className="ico ico-facebook-icon"></i>
						</a>
						<a href="#" className="footer__social-link">
							<i className="ico ico-twitter-icon"></i>
						</a>
					</div>
				</div>
				<div className="bottom-wrapper">
					<div className="footer__copy">
						Copyright 2021 Gett Images. Wykonanie:{' '}
						<a href="#" target="_blank">
							BERBER
						</a>
					</div>
					<div className="footer__nav">
						<span
							onClick={(e) => {
								setModalSlug('client.termsModal');
								setOpened(true);
							}}
							className="footer__nav-link"
						>
							{contentsQuery.data['client.termsModal.nav']}
						</span>
						<span
							onClick={(e) => {
								setModalSlug('client.downloadTermsModal');
								setOpened(true);
							}}
							className="footer__nav-link"
						>
							{contentsQuery.data['client.downloadTermsModal.nav']}
						</span>
						<span
							onClick={(e) => {
								setModalSlug('client.restrictionsModal');
								setOpened(true);
							}}
							className="footer__nav-link"
						>
							{contentsQuery.data['client.restrictionsModal.nav']}
						</span>
						<span
							onClick={(e) => {
								setModalSlug('client.pricesModal');
								setOpened(true);
							}}
							className="footer__nav-link"
						>
							{contentsQuery.data['client.pricesModal.nav']}
						</span>
						<span
							onClick={(e) => {
								setModalSlug('client.privacyModal');
								setOpened(true);
							}}
							className="footer__nav-link"
						>
							{contentsQuery.data['client.privacyModal.nav']}
						</span>
					</div>
				</div>
			</div>
			<Modal
				opened={opened}
				withCloseButton={false}
				centered
				onClose={() => setOpened(false)}
				size="lg"
			>
				<div className="modal__inner">
					<div className="modal__wrapper">
						<h3 className="modal__title">
							{contentsQuery.data[`${modalSlug}.title`]}
						</h3>
						<a
							onClick={() => setOpened(false)}
							className="modal__close-button js-modal-close"
						>
							<i className="ico ico-close-icon"></i>
						</a>
					</div>
					<div className="modal__content">
						<div className="wysiwyg">
							{contentsQuery.data[`${modalSlug}.content`]}
						</div>
					</div>
				</div>
			</Modal>
		</footer>
	);
};

export default Footer;
