import React from 'react';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { getMailQueues } from '../../api/get';

const Notices = () => {
	const { data } = useQuery('mail_queues', getMailQueues);

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="notifications">
					<h2 className="main-title main-title--25">Powiadomienia</h2>
					<div className="main-text wysiwyg">
						<p>
							Poniżej znajdziesz powiadomienia dotyczące Twojego konta i Twoich
							pobrań.
						</p>
					</div>
					{!data?.length && (
						<div className="notifications__heading">Brak powiadomień</div>
					)}
					{!!data?.length && (
						<div className="notifications__heading">
							<div className="notifications__heading-left">TREŚĆ</div>
							<div className="notifications__heading-left">DATA</div>
						</div>
					)}

					{data?.map((notification) => {
						return (
							<>
								<div className="notifications__content">
									<div
										className="notifications__text"
										dangerouslySetInnerHTML={{
											__html: notification?.content.replace(
												/{{add_download_conditions_file_pdf}}/g,
												''
											),
										}}
									></div>
									<div className="notifications__date">
										{notification.sent
											? new Date(notification.sent).toLocaleDateString()
											: new Date(notification.lastAttempt).toLocaleDateString()}
									</div>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</motion.div>
	);
};

export default Notices;
