import React from 'react';
import {motion} from "framer-motion";
import {useQuery} from "react-query";
import {authorizedGet} from "../../fetchFunctions";

const DUMMY_DOCUMENTS = [
    {
        id:1,
        title: "Pozwolenia i przyzwolenia dla pobrań",
        file: null
    },
    {
        id:2,
        title: "Warunki korzystania z serwisu",
        file: null
    },
]

const Documents = () => {
    const {data: documents} = useQuery("documents", () => authorizedGet("/documents"))

    const getPdf = (invoice = "1") => {
        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}api/purchase_invoices/${invoice}/get_pdf`;
            httpClient.open('get', pdfLink, true);
            httpClient.setRequestHeader( "Authorization", `Bearer asdsa`)
            httpClient.responseType = "blob";
            httpClient.onload = function() {
                const file = new Blob([httpClient.response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = "#";
                link.download = `${invoice.title}.pdf`;
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
            };
            httpClient.send();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .3
                }
            }}
            className="white-box white-box--library">
            <div className="white-box__inner">
                <div className="documents">
                    <h2 className="main-title main-title--25">Dokumenty informacyjne</h2>
                    <div className="main-text wysiwyg">
                        <p>Poniżej znajdziesz dokumenty doyczące warunków i przyzwoleń. Śledź na bieżąco zmiany w
                            tych <br/>
                                dokumentach, nie znajomość ich nie zwalnia Ciebie od ich przestrzegania.</p>
                    </div>
                    {/*{documents?.length === 0 &&  <div className="documents__item">*/}
                    {/*    <div className="documents__text">*/}
                    {/*      Brak dokumentów..*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    {DUMMY_DOCUMENTS?.map(doc => {
                        return (
                            <div className="documents__item">
                                <div className="documents__text">
                                    {doc.title}
                                </div>
                                 <span
                                     style={{cursor: "pointer"}}
                                     onClick={()=> getPdf(doc)}
                                     href="#" className="documents__download" target="_blank">
                                    <i className="ico ico-download-icon"></i>
                                    <span>Pobierz</span>
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </motion.div>
    );
};

export default Documents;