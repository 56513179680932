import React, { useMemo, useState } from 'react';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import DownloadedItems from '../../components/DownloadedItems/DownloadedItems';
import YourData from '../../components/YourData/YourData';
import PasswordChange from '../../components/PasswordChange/PasswordChange';
import Recipes from '../../components/Recipes/Recipes';
import Documents from '../../components/Documents/Documents';
import Notices from '../../components/Notices/Notices';
import EmailChange from '../../components/EmailChange/EmailChange';
import Help from '../../components/Help/Help';
import Libary from '../../components/Libary/Libary';
import LibarySideMenu from '../../components/Libary/LibarySideMenu';
import Conversations from '../../components/Conversations/Conversations';

const Main = ({ setChosenMenuItem, chosenMenuItem, unathLib }) => {
	const [chosenCat, setChosenCat] = useState();

	const content = useMemo(
		() =>
			unathLib ? (
				<Libary
					unathLib={unathLib}
					chosenCat={chosenCat}
					setChosenCat={setChosenCat}
				/>
			) : (
				getContent(chosenMenuItem, chosenCat, setChosenCat)
			),
		[chosenMenuItem, chosenCat, setChosenCat, unathLib]
	);

	return (
		<main className="main">
			<section className="section">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-xlg-3">
							{chosenMenuItem !== 'Twoja biblioteka' && (
								<AsideMenu
									chosenMenuItem={chosenMenuItem}
									setChosenMenuItem={setChosenMenuItem}
								/>
							)}
							{chosenMenuItem === 'Twoja biblioteka' && (
								<LibarySideMenu
									unathLib={unathLib}
									chosenCat={chosenCat}
									setChosenCat={setChosenCat}
								/>
							)}
						</div>

						<div className="col-lg-9">{content}</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Main;

function getContent(tab, chosenCat, setChosenCat) {
	switch (tab) {
		case 'Pobrane materiały':
			return <DownloadedItems />;
		case 'Twoje dane':
			return <YourData />;
		case 'Zmiana hasła':
			return <PasswordChange />;
		case 'Zmiana maila':
			return <EmailChange />;
		case 'Dokumenty księgowe':
			return <Recipes />;
		case 'Dokumenty informacyjne':
			return <Documents />;
		case 'Powiadomienia':
			return <Notices />;
		case 'Korespondencja':
			return <Conversations />;
		case 'Pomoc':
			return <Help />;
		case 'Twoja biblioteka':
			return <Libary chosenCat={chosenCat} setChosenCat={setChosenCat} />;

		default:
			return <DownloadedItems />;
	}
}
